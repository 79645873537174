import { default as jwtDecode } from "jwt-decode";
import md5 from "md5";
import { EHR_PROVIDERS as EhrProviders } from "@/constants/providers";

export function getTenantFromTokenResponse(provider, tokenResponse) {
    if (provider === EhrProviders.CERNER) {
        return tokenResponse.tenant;
    } else if (provider === EhrProviders.EPIC) {
        return md5(jwtDecode(tokenResponse.id_token).iss);
    }
}
