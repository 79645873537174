import Vue from "vue";
import VueRouter from "vue-router";
import Root from "../views/root/root.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "",
        component: Root,
        children: [
            {
                path: "/launch/:provider",
                name: "launch",
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "launch" */ "../views/launch/launch.vue"
                    )
            },
            {
                path: "/callback",
                name: "callback",
                component: () =>
                    import(
                        /* webpackChunkName: "callback" */ "../views/callback/callback.vue"
                    )
            },
            {
                path: "/itopia",
                name: "itopia",
                component: () =>
                    import(
                        /* webpackChunkName: "itopia" */ "../views/itopia/itopia-main.vue"
                    )
            },
            {
                path: "/404",
                name: "notFound",
                component: () =>
                    import(
                        /* webpackChunkName: "error" */ "../views/error/error.vue"
                    )
            },
            {
                path: "/500",
                name: "internalError",
                component: () =>
                    import(
                        /* webpackChunkName: "error" */ "../views/error/error.vue"
                    )
            }
        ]
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

// Catch any path not handle by router and redirect to 404
router.beforeEach((to, from, next) => {
    if (!to.matched.length) {
        next("/404");
    } else {
        next();
    }
});

export default router;
