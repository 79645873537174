export const ERROR_LEVELS = {
    WARNING: "WARNING",
    ERROR: "ERROR"
};

export const ERROR_403 = "(Error 403)";

export const DATABASE_EXCEPTION = "DatabaseException";
export const NETWORK_ERROR = "Network Error";

export const PLEASE_CONTACT_SYS_ADMIN =
    "Please contact your system administrator.";
export const UNKNOWN_INTERNAL_ERROR = "An unknown internal error occurred.";
export const UNABLE_TO_FIND_ENCOUNTER = "Unable to find encounter.";
export const UNABLE_TO_REACH_API = "Unable to reach EHR API.";
export const UNABLE_TO_REACH_EHRSC = "Unable to reach EHR Session Creator.";
export const USER_CONFIGURATION_ISSUE = "User configuration issue";
export const PROVIDER_NOT_SUPPORTED = "Provider not supported";
export const TENANT_IS_NOT_REGISTERED_IN_API =
    "The Tenant is not registered in the EHR API.";

export const DEFAULTING_TO_OR = "defaulting to OR context.";
export const EHR_CONTEXT_VAR_HAS_INVALID_DATA =
    "EHR software context variable has invalid data, value should be: or/cathir";
export const EHR_CONTEXT_VAR_MISSING =
    "EHR software context variable is missing";
