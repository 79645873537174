// Constants File for Store mutations and actions

export const MUTATIONS = {
    SET_ENCOUNTER_ID: "SET_ENCOUNTER_ID",
    SET_CASE_DOC_VIEW: "SET_CASE_DOC_VIEW",
    SET_TENANT_AND_ID_TOKEN: "SET_TENANT_AND_ID_TOKEN",
    SET_URL: "SET_URL",
    SET_LOGIN_SUCCESSFUL: "SET_LOGIN_SUCCESSFUL",
    SET_HTTP_ERROR: "SET_HTTP_ERROR",
    SET_PROVIDER: "SET_PROVIDER",
    SET_CASE_ID: "SET_CASE_ID"
};

export const ACTIONS = {
    READ_ENCOUNTER_FROM_FHIR_API: "readEncounterFromFhirApi",
    SET_CASE_DOCUMENTATION_VIEW: "setCaseDocumentationView",
    SET_TENANT_AND_ID_TOKEN: "setTenantAndIdToken",
    FETCH_ITOPIA_URL: "fetchItopiaUrl",
    ITOPIA_LOGIN: "itopiaLogin",
    SET_PROVIDER: "setProvider",
    SET_CASE_ID: "setCaseId"
};
