import axios from "axios";
import store from "@/store";
import https from "https";
import * as Constants from "@/constants/misc-constants";

const headers = {};
const apiInstance = axios.create({
    headers: headers,
    withCredentials: false,
    httpsAgent: new https.Agent({
        rejectUnauthorized: trueUnlessEnvironmentVariableOverride()
    })
});
apiInstance.interceptors.request.use(function (config) {
    // Interceptor = Do something before request is sent
    const authKey = store.getters.idToken;
    if (authKey) {
        config.headers[
            Constants.AUTHORIZATION
        ] = `${Constants.BEARER} ${authKey}`;
    }
    return config;
});

function trueUnlessEnvironmentVariableOverride() {
    return process.env.VUE_APP_HTTPS_REJECT_UNAUTHORIZED &&
        process.env.VUE_APP_HTTPS_REJECT_UNAUTHORIZED === "false"
        ? false
        : true;
}
function handleError(url, error) {
    return {
        url: url,
        error: error
    };
}

export default {
    get(url, params, config = {}) {
        const axiosArgs = Object.assign(
            { params: params, crossdomain: true },
            config
        );
        return apiInstance.get(url, axiosArgs).then(
            (response) => {
                return response.data;
            },
            (error) => {
                return Promise.reject(handleError(url, error));
            }
        );
    },
    post(url, body) {
        return apiInstance
            .post(url, body)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(handleError(url, error));
            });
    }
};
