// Constants (Miscellaneous) file

export const TENANT_ID_PLACEHOLDER = "{tenantId}";

export const AUTHORIZATION = "Authorization";
export const BEARER = "Bearer";

export const NOT_APPLICABLE = "N/A";
export const CERNER_ENCOUNTER_IDENTIFIER = "FIN";
export const EPIC_CASE_IDENTIFIER = "Case";

export const PROVIDER = "provider";

export const CERNER = "cerner";
export const STRING_TYPE = "string";
export const OR = "or";
export const CATHIR = "cathir";
export const SUCCESSFUL = "successful";
export const EPIC = "epic";

export const KUBERNETES = "KUBERNETES";

export const CONSUMER_CODE = "consumer_code";
export const ORDER_NUM = "order_num";

export const ENCODED_SEPARATOR = "%3B";
